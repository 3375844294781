import "./index.scss";
import { defineComponent, ref } from "vue";
import BasisGroup from "@/views/basisGroup/index.vue";
import { useRoute } from "vue-router";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
import Nav from "@/components/nav/nav.vue";
export default defineComponent({
    components: {
        BasisGroup: BasisGroup,
        Nav: Nav,
        HomeJumbotron: HomeJumbotron
    },
    setup: function () {
        var route = useRoute();
        document.documentElement.scrollTop = 0;
        var navActiveIndex = ref("4-1");
        var newsInfo = ref("");
        var routeQuery = route.query;
        if (route.query.newsInfo) {
            var str = decodeURIComponent(routeQuery.newsInfo);
            newsInfo.value = JSON.parse(str);
        }
        return {
            navActiveIndex: navActiveIndex,
            newsInfo: newsInfo
        };
    },
});
